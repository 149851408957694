import { Component, OnInit } from '@angular/core';
import { TVFRecord, GetTVFRecord_Options, UpdateFBEStatus_Options } from '../../models/teacher-fbeverification.model';
import { TeacherFBEVerificationService } from '../../services/teacher-fbeverification.service';
import { FBEStatusEnum } from 'src/enums/fbe-status-enum';
import { TrainingCourseTypeEnum } from 'src/enums/training-course-type-enum';

@Component({
  selector: 'app-teacher-fbeverification-component',
  templateUrl: './teacher-fbeverification-component.component.html',
  styleUrls: ['./teacher-fbeverification-component.component.scss']
})
export class TeacherFBEVerificationComponentComponent implements OnInit {

  constructor(private TeacherFBEVerificationService: TeacherFBEVerificationService) {

  }
  EmailGUID: string = "";
  TVFRecord: TVFRecord = new TVFRecord();
  TVFRecord_LoadComplete = false;
  TVFRecord_ButtonsAvailable = false;
  TVFRecord_Approved = false;
  TVFRecord_Denied = false;

  //FBESummary: 
  ngOnInit() {
    var URLQueryString = window.location.search.substring(1);
    this.EmailGUID = this.ParseQueryString(URLQueryString)["EmailGUID"];
    var EmailGUIDIsValid = false;

    if (this.EmailGUID != undefined) {
      if (this.EmailGUID.length == 36) {
        EmailGUIDIsValid = true;
      }
    }

    if (EmailGUIDIsValid != true) {
      return;
    }

    var _GetTVFRecord_Options = new GetTVFRecord_Options();

    _GetTVFRecord_Options.EmailGUID = this.EmailGUID;


    //given the GUID, make a call to dbo.CandidateFBEEmail
    //and get the summary for display        
    this.TeacherFBEVerificationService.TVF_GetTVFRecord(_GetTVFRecord_Options).subscribe((data: TVFRecord) => {
      this.TVFRecord = data;

      this.TVFRecord_LoadComplete = true;

      //Needs verification
      if (this.TVFRecord.fbeStatusId == FBEStatusEnum.NeedsVerification) {
        this.TVFRecord_ButtonsAvailable = true;
      }//Approved OR Verified
      else if (this.TVFRecord.fbeStatusId == FBEStatusEnum.Approved
        || this.TVFRecord.fbeStatusId == FBEStatusEnum.Verified
        || this.TVFRecord.fbeStatusId == FBEStatusEnum.TeacherVerified) {
        this.TVFRecord_Approved = true;
      }//Teacher denied
      else if (this.TVFRecord.fbeStatusId == FBEStatusEnum.TeacherDenied) {
        this.TVFRecord_Denied = true;
      }
    });
  }

  ApproveFBE() {
    const approvalConfirmed = confirm("Are you sure you want to approve this FBE?");

    if (!approvalConfirmed) {
      alert("FBE approval was cancelled.");
      return;
    }

    const updateFBEStatusOptions = new UpdateFBEStatus_Options();
    updateFBEStatusOptions.CandidateFBEID = this.TVFRecord.fbeId;

    const { trainingCourseTypeId } = this.TVFRecord;
    const isInPersonFBE = trainingCourseTypeId === TrainingCourseTypeEnum.InPersonFBE ||
      trainingCourseTypeId === TrainingCourseTypeEnum.SecondaryInPersonFBE;

    //We keep status "Verified" to support old process where there is not used TrainingCourseType
    updateFBEStatusOptions.TargetFBEStatusID = isInPersonFBE
      ? FBEStatusEnum.TeacherVerified.toString()
      : FBEStatusEnum.Verified.toString();

    this.TeacherFBEVerificationService.TVF_UpdateFBEStatus(updateFBEStatusOptions).subscribe(() => {
      this.TVFRecord_ButtonsAvailable = false;
      this.TVFRecord_Approved = true;
    });
  }

  DenyFBE() {
    var DenialConfirmed = confirm("Are you Sure you want to Deny this FBE?");

    if (DenialConfirmed == true) {
      var _UpdateFBEStatusOptions = new UpdateFBEStatus_Options();
      _UpdateFBEStatusOptions.CandidateFBEID = this.TVFRecord.fbeId;
      _UpdateFBEStatusOptions.TargetFBEStatusID = FBEStatusEnum.TeacherDenied.toString();

      this.TeacherFBEVerificationService.TVF_UpdateFBEStatus(_UpdateFBEStatusOptions).subscribe(data => {
        this.TVFRecord_ButtonsAvailable = false;
        this.TVFRecord_Denied = true;
      });
    }
    else {
      alert("FBE Denial was Cancelled.");
    }
  }

  //helper functions
  //given the input Query String (not the whole URL,
  //just the part to the right of the '?')
  //return an object with properties for each Query Key Name
  ParseQueryString(URLQueryString) {
    var vars = URLQueryString.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      var key = decodeURIComponent(pair[0]);
      var value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        var arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  }
}
