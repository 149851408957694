export enum TrainingCourseTypeEnum {
  ContentTraining = 1,
  Core = 2,
  FieldBasedExperience = 3,
  InternshipProject = 4,
  Supplemental = 5,
  TestReviews = 6,
  InPersonFBE = 7,
  VirtualFBE = 8,
  SecondaryInPersonFBE = 10
}
